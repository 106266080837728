import React from "react";
import styled from "styled-components";

const ContainerWindow = styled.div`
  display: block;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #f2fcff;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1800px) {
  }
`;
export const WindowContainer = ({ children }) => {
  return <ContainerWindow>{children}</ContainerWindow>;
};
