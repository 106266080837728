import React from "react";
import styled from "styled-components";
import "../App.css";

import { Link } from "react-router-dom";

import logo from "../images/CEL_logo_only.png";
import fbLogo from "../images/fb_logo.png";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  background-color: #61a2b5;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 20px;

  width: 80vw;
  max-width: 1300px;

  @media only screen and (max-width: 1100px) {
    width: 95vw;
  }
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    width: 95vw;
    flex-flow: row nowrap;
    justify-content: space-around;
  }
  @media only screen and (min-width: 1024px) {
    
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Logo = styled.img`
  order: 1;
  flex-basis: 29%;
  max-width:150px;
  margin: 2vh 2vw 2vh 2vw;

  @media only screen and (max-width: 1100px) {
    max-width: 100px;
  }
`;

const TextSectionMid = styled.div`
  order: 2;
  flex-basis: 29%;
  align-self: flex-start;
  margin-left: 2vw;
  margin-right: 2vw;
`;
const TextSectionEnd = styled.div`
  order: 3;
  flex-basis: 29%;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 2vw;
  margin-right: 5vw;
`;

const Heading = styled.div`
  font-size: 1.5em;
  text-transform: uppercase;
  color: white;
  margin-top: 5vh;
  text-align: center;
`;

const Text = styled.div`
  font-size: 1em;
  color: white;
  text-align: center;
`;

const LinkStyle = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const Footer = () => {
  return (
    <Container>
      <div id="footer" />
      <FlexContainer>
        <Link to="/"><Logo src={logo} /></Link>
        <TextSectionMid>
          <Heading>we acknowledge</Heading>
          <Text>
            We acknowledge the Gumbaynggirr people as the traditional custodians
            of the land on which we live, work, and learn. We pay our respects
            to all Elders past, present, and emerging.
          </Text>
          {/* <Heading>policies</Heading>
          <Text>
            <Link href={policy}> Vulnerable Persons Policy</Link>
          </Text> */}
        </TextSectionMid>
        <TextSectionEnd>
          <Heading>contact us</Heading>
          <Text>
            <p>
              <a id="phone" href="tel:+61423362844">
                Phone: <LinkStyle>0423 362 844</LinkStyle>
              </a>
            </p>
            <p>
              <a href="mailto: info@cel.org.au">
                Email: <LinkStyle>info@cel.org.au</LinkStyle>
              </a>
            </p>
            <a
              href="https://www.facebook.com/CentreForEcologicalLearning/events"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={fbLogo} alt="facebook logo" height="60px"></img>
            </a>
          </Text>
        </TextSectionEnd>
      </FlexContainer>
    </Container>
  );
};

export default Footer;
