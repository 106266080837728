import React from "react";
import { Parallax } from "react-parallax";
import "../App.css";

import { PageContainer } from "../components/siteStyling/containerPage";
import { SectionContainerLge } from "../components/siteStyling/containerSection";
import { TextSection } from "../components/siteStyling/containerText";
import { ParallaxLge } from "../components/siteStyling/containerParallax";

import {
  SubHeadingDark,
  SubHeadingLight,
  LargeDarkText,
  LargeLightText,
  TextDark,
  Link,
} from "../components/siteStyling/textStyling";
import { Button } from "../components/siteStyling/button";

import Footer from "../components/footer";

import pic01 from "../images/donate01.jpg";
import pic02 from "../images/donate02.jpg";

const Donate = () => {
  return (
    <PageContainer>
      <SectionContainerLge>
        <Parallax blur={{ min: -15, max: 15 }} bgImage={pic01} strength={-200}>
          <ParallaxLge>
            <SubHeadingLight>believe in what we do</SubHeadingLight>
            <LargeLightText>and want to support us?</LargeLightText>
          </ParallaxLge>
        </Parallax>
      </SectionContainerLge>
      <TextSection>
        <SubHeadingDark>
          We are nature. Some people have forgotten that, but we
          haven’t.{" "}
        </SubHeadingDark>
        <LargeDarkText>
          CEL continues to work towards our goal of helping all sorts of human
          beings remember. Your tax-deductible donations help us continue this
          important work.
        </LargeDarkText>
      </TextSection>
      <SectionContainerLge>
        <Parallax blur={{ min: -15, max: 15 }} bgImage={pic02} strength={200}>
          <ParallaxLge>
          </ParallaxLge>
        </Parallax>
      </SectionContainerLge>
      <TextSection>
        <SubHeadingDark>
          Your generous donations are appreciated and help keep our organisation
          running.
        </SubHeadingDark>
        <Button>
          <a
            href="https://square.link/u/XBdzEK2T"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Donate now
          </a>
        </Button>
        <TextDark>
          You can also donate by electronic transfer or cash deposit to:
        </TextDark>
        <TextDark>
          Ecological Learning Fund
          <br />
          Bananacoast Community Credit Union
          <br />
          BSB: 533000
          <br />
          Account: 32858157
        </TextDark>
        <TextDark>
          Please email us at{" "}
          <Link>
            <a href="mailto: info@cel.org.au">info@cel.org.au</a>
          </Link>{" "}
          and we will happily and gratefully send you a tax receipt for your
          records.
        </TextDark>
      </TextSection>
      <Footer />
    </PageContainer>
  );
};

export default Donate;
