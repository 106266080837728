import React from "react";
// import { useWindowDimensions } from "react-native";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";
import ProgressiveImage from "../progressiveImage";

// import { data } from "./data";
import nature from "../../images/Nature-Academy.jpg";
import deepEcology from "../../images/deep-ecology.jpg";
import learningAlliance from "../../images/learning-alliance.jpg";
import activities from "../../images/activities.jpg";
import natureMini from "../../images/Nature-Academy_mini.jpg";
import deepEcologyMini from "../../images/deep-ecology_mini.jpg";
import learningAllianceMini from "../../images/learning-alliance_mini.jpg";
import activitiesMini from "../../images/activities_mini.jpg";

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 98vw;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SliderImage = styled.img`
  width: 100vw;
  height: 30vh;
  object-fit: cover;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SliderText = styled.h1`
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  position: relative;
  top: -20vh;
  float: right;
  padding-right: 10px;
  margin-bottom: -10vh;

  @media only screen and (min-width: 480px) {
    top: -25vh;
  }
  @media only screen and (min-width: 768px) {
    padding-right: 10vw;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Image = styled.div`
  display: block;
  margin: 15px auto;
  width: 100vw;
  height: 30vh;  

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Slider = () => {
  // if (deviceWidth > 750) {
  return (
    <Container>
      <AliceCarousel autoPlay infinite={true} autoPlayInterval="5000">
        <a
          href="https://workthatreconnects.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image>
            <ProgressiveImage
              src={deepEcology}
              placeholderSrc={deepEcologyMini}
              alt="A face is barely noticeable in a picture of nature."
            />
            <SliderText>Deep Ecology</SliderText>
          </Image>
        </a>
        <Link to="/community">
          <Image>
            <ProgressiveImage
              src={learningAlliance}
              placeholderSrc={learningAllianceMini}
              alt="A group engaged in an outdoor lesson."
            />
            <SliderText>Community Education</SliderText>
          </Image>
        </Link>
        <Link to="/natureYouths">
          <Image>
            <ProgressiveImage
              src={activities}
              placeholderSrc={activitiesMini}
              alt="A group of youths prepare for an outdoor adventure."
            />
            <SliderText>Activities for Young People</SliderText>
          </Image>
        </Link>
        <Link to="/natureAdults">
          <Image>
            <ProgressiveImage
              src={nature}
              placeholderSrc={natureMini}
              alt="A group of people with mud smeared faces stand for a group photo."
            />
            <SliderText>Nature Connection Mentoring</SliderText>
          </Image>
        </Link>
      </AliceCarousel>
    </Container>
  );
};

export default Slider;
