import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const VideoResponsive = styled.div`
  width: 90vw;
  height: 45vh;
  padding: 5%;
  position: relative;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 800px;
    height: 400px;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Frame = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const YoutubeEmbed = ({ embedId }) => (
  <VideoResponsive>
    <Frame
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </VideoResponsive>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
