import * as React from "react";
import "./App.css";
import Navbar from "./components/multilevel-dropdown-menu/Navbar";

import { WindowContainer} from "./components/siteStyling/containerWindow";

function App() {
  return (
    <WindowContainer>
      <Navbar />
    </WindowContainer>
  );
}

export default App;
