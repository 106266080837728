import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

import Footer from "../components/footer";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const TextBox = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const SmallHeading = styled.h3`
  font-size: 1.3em;
  text-align: center;
  margin: 1vh 2vw;
  color: #666666;
  text-transform: uppercase;
`;

const ListBox = styled.div`
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const List = styled.ul`
  padding: 0 15px;
  list-style-type: none;
  font-size: 1em;
  text-align: center;
  margin: 1vh 1vw;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const ListItem = styled.li`
  padding-top: 0px;
  list-style-type: none;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const About = () => {
  return (
    <Container>
      <TextBox>
        <MainHeading>About CEL</MainHeading>
        <TextDark>
          The Centre for Ecological Learning (CEL) coordinates, supports and
          delivers programs that deepen our relationship with ourselves and the
          natural world. Offering nature-based experiences and education to
          people of all ages, we aim to nourish, inspire and equip people with
          the knowledge and skills that support ecological well-being in local
          communities and broader networks.
        </TextDark>
        <TextDark>
          Our focus is EDUCATION, INSPIRATION and EMPOWERMENT. By providing
          educational experiences that encourage curiosity, increase awareness
          and knowledge about the natural world, we aim to foster a sense of awe
          that deepens people’s respect and value for the natural world, so that
          we may emerge nourished and inspired to build more ecological
          sustainable lifestyles and communities.
        </TextDark>
        <TextDark>
          The practice of "re-wilding" informs much of what CEL does. We are
          biologically and psychologically hard-wired to live in the natural
          world, but modern life leaves many of us without enough “wild” time
          for our well-being. We believe that we all need the opportunity to
          reawaken our wild nature and strengthen our connection to the planet,
          in order to move towards a life-sustaining future for humans and
          more-than-humans alike.
        </TextDark>
        <SubHeading>Our Story</SubHeading>
        <TextDark>
          Our story began in 2007 as the Bellingen Environmental Youth
          Experience (Bellingen EYE) when a small group of high school students
          decided to “go bush”, under the mentorship of two of their former
          teachers. Weekend camps and bushwalks led to a deepening connection to
          nature, which saw the young people build leadership skills, express
          their creativity, find their voice, and become empowered community
          change agents. In 2011, a group of EYE mentors formalised and expanded
          the organisation to the Centre for Ecological Learning Inc.
        </TextDark>
        <TextDark>
          Since then, CEL has grown to deliver and support a diverse range of
          environmental educational programs with hundreds of children, young
          people, and adults participating. In addition to bushwalks and camps,
          the following projects have been developed as part of CEL's
          educational activities:
          <br />
          <br />
          <ListContainer>
            <ListBox>
              <SmallHeading>For children and young people:</SmallHeading>
              <List>
                <ListItem>&#8226; Beetle Buddies</ListItem>
                <ListItem>&#8226; Wild Camps</ListItem>
                <ListItem>&#8226; Young Naturalists Club</ListItem>
                <ListItem>&#8226; YAP (Youth Against Plastic)</ListItem>
              </List>
            </ListBox>
            <ListBox>
              <SmallHeading>For the wider community:</SmallHeading>
              <List>
              <ListItem>&#8226; Bellingen Shire Learning Alliance</ListItem>
              <ListItem>&#8226; Bellingen Sustainability Centre</ListItem>
              <ListItem>&#8226; Waste Not Bellingen</ListItem>
              <ListItem>&#8226; Transition Bellingen</ListItem>
              <ListItem>&#8226; Leaf Festival (Bello Winter Music Festival)</ListItem>
              <ListItem>&#8226; Deep Ecology workshops</ListItem>
              <ListItem>&#8226; Nature Academy nature mentoring training</ListItem>
              </List>
            </ListBox>
            <ListBox>
              <SmallHeading>CEL has also supported and/or partnered with:</SmallHeading>
              <List>
              <ListItem>&#8226; Coffs Coast Regional Science Hub</ListItem>
              <ListItem>&#8226; Jaliigirr Biodiversity Alliance</ListItem>
              <ListItem>&#8226; Bellingen Youth Hub</ListItem>
              </List>
            </ListBox>
          </ListContainer>
        </TextDark>

        <TextDark>
          CEL is a registered charity with Deductible Gift Recipient status and
          is listed on the Register of Environmental Organisations. CEL has
          established a Public Trust Fund (the "Ecological Learning Fund") for
          the specific purpose of supporting our environmental education
          objectives through donations and gift funding.
        </TextDark>
      </TextBox>
      <Footer />
    </Container>
  );
};

export default About;
