import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

import Footer from "../components/footer";

const TextBox = styled.div`
  width: 100vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Temp = () => {
  return (
    <>
      <TextBox>
        <h1>Site under reconstruction</h1>
        <p>Check back soon!</p>
      </TextBox>
      <Footer />
    </>
  );
};

export default Temp;
