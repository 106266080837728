import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

import Footer from "../components/footer";
import SmallSlider from "../components/slider/smallSlider";

import HeaderImage from "../images/NatureYouthPage/01.jpg";
import slide01 from "../images/NatureYouthPage/02.jpg";
import slide02 from "../images/NatureYouthPage/03.jpg";
import slide03 from "../images/NatureYouthPage/04.png";
import slide04 from "../images/NatureYouthPage/05.jpg";
import slide05 from "../images/NatureYouthPage/06.png";
import slide06 from "../images/NatureYouthPage/07.png";
const SliderImages = [slide01, slide02, slide03, slide04, slide05, slide06];

const ImageSection = styled.div`
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 98vw;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const BGImage = styled.div`
  width: 100vw;
  height: 40vh;
  max-width: 960px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1800px) {
    height: 80vh;
    max-width: 1200px;
  }
`;
const Image = styled.img`
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2vh 5vw;
`;
const LightText = styled.p`
  font-size: 1.5em;
  text-align: center;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  @media only screen and (min-width: 1000px) {
    font-size: 2em;
  }
`;

const NatureYouths = () => {
  return (
    <PageContainer>
      <TextBox>
        <MainHeading>nature connection activities for young people</MainHeading>
        <ImageSection>
        <Parallax blur={{ min: -15, max: 15 }} bgImage={HeaderImage} strength={100}>
          <BGImage>
            <TextBox>
              <LightText>We are the future</LightText>
            </TextBox>
          </BGImage>
        </Parallax>
      </ImageSection>
        <TextDark>
          <br />
          <br />
          CEL offers a variety of nature connection programs for schools and
          community organisations, facilitated by experienced and passionate
          mentors – some of whom participated in our programs as young people
          themselves! <br />
          <br />
          The origin of the Centre for Ecological Learning (CEL) was in
          Bellingen EYE (Environmental Youth Experience). EYE began in 2007 when
          a group of young people asked two of their former teachers to help
          them “go bush”. After organising a number of bushwalks and weekend
          camps, the energy of this group radiated out and soon other teenagers
          were joining in the camps – not only having fun, but also developing a
          myriad of other leadership and bush skills. A deepening connection to
          the natural world inspired them to want to do something more.
          Self-education about environmental issues, plus mentoring from
          inspirational community members, soon saw the group develop into an
          active environmental youth group, and organising other events such as
          campaigns, markets, and the infamous “EYEBall”.
          <br />
          <br />
          <br />
        </TextDark>
        <SmallSlider images={SliderImages} />
        <TextDark>
          <br />
          Over the years, these events and camps continued to take many forms,
          and hundreds of young people have participated, deepening their love
          of the planet and their sense of agency. While the activities of CEL
          have now branched out to include all ages, the importance of working
          with young people remains as a key focus, as nature connection in this
          transitional stage of life can often lead towards a lifetime of
          understanding what is needed for the healing of our planet.
          <br />
          <br />
        </TextDark>
      </TextBox>
      <Footer />
    </PageContainer>
  );
};

export default NatureYouths;
