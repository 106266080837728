import React from "react";
import styled from "styled-components";

const ContainerSectionLge = styled.div`
  height: 40vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
    height: 50vh;
  }
  @media only screen and (min-width: 768px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1800px) {
    height: 50vh;
  }
`;
export const SectionContainerLge = ({ children }) => {
  return <ContainerSectionLge>{children}</ContainerSectionLge>;
};

const ContainerSectionMd = styled.div`
  height: 30vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;

  @media only screen and (min-width: 480px) {
    height: 50vh;
  }
  @media only screen and (min-width: 768px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 500px;
    width: 98vw;
  }
  @media only screen and (min-width: 1800px) {
    
  }
`;
export const SectionContainerMd = ({ children }) => {
  return <ContainerSectionMd>{children}</ContainerSectionMd>;
};
