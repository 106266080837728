import React, { useState } from "react";
import styled from "styled-components";
import logo2 from "../../images/CEL_logo.png";

const LogoWrapper = styled.div`
  display: block;
  float: left;
  width: 15vw;

  @media only screen and (max-width: 768px) {
    padding: 0px 2.76vw;
  }
`;

const StyledLogo = styled.img`
  height: 58px;

  @media only screen and (max-width: 768px) {
    padding-left: 0px;
  }
`;

const Logo = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 50) {
      setVisible(true);
    } else if (scrolled <= 50) {
      setVisible(false);
    }
  };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  window.addEventListener("scroll", toggleVisible);

  return (
    <LogoWrapper>
      <a href="/">
        <StyledLogo
          src={logo2}
          style={{ height: visible ? "58px" : "100px", transition: "height .5s" }}
        />
      </a>
    </LogoWrapper>
  );
};

export default Logo;
