import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

import Footer from "../components/footer";
import SmallSlider from "../components/slider/smallSlider";

import HeaderImage from "../images/DeepEcologyPage/01.jpg";
import Image02 from "../images/DeepEcologyPage/02.jpg";
import Image03 from "../images/DeepEcologyPage/03.jpg";
import Image04 from "../images/DeepEcologyPage/04.jpg";
import Image05 from "../images/DeepEcologyPage/05.jpg";
import Image06 from "../images/DeepEcologyPage/06.jpg";
import Image07 from "../images/DeepEcologyPage/07.jpg";

const SliderImages = [Image03, Image04, Image05, Image06, Image07];

const ImageSection = styled.div`
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 98vw;
  }
  @media only screen and (min-width: 1800px) {
  }
`;
const BGImage = styled.div`
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    height: 40vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1800px) {
    height: 80vh;
  }
`;

const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2vh 5vw;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const LightText = styled.p`
  font-size: 1.5em;
  text-align: center;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  @media only screen and (min-width: 1000px) {
    font-size: 2em;
  }
`;
const LargerDarkText = styled.p`
  font-size: 2.1em;
  text-align: center;
  margin: 1vh 2vw;
  color: #666666;
`;
const Quote = styled.p`
  font-size: 1em;
  text-align: center;
  margin: 1vh 2vw;
  color: #666666;
  border-left: 4px solid #2997ab;
  padding-left: 10px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 80%;
  }
  @media only screen and (min-width: 1600px) {
    width: 60%;
  }
`;

const TextButton = styled.div`
  width: 80%;
  max-width: 500px;
  margin: 5px;
`;

const TextGroupCol = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin: 2vh 10vw;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const DeepEcology = () => {
  return (
    <PageContainer>
      <TextBox>
        <MainHeading>Deep Ecology & the work that reconnects</MainHeading>
      </TextBox>
      <ImageSection>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={HeaderImage}
          strength={100}
        >
          <BGImage>
            <TextBox>
              <LightText>
                CEL offers Deep Ecology workshops, study groups, and retreats
                for adults and young people
              </LightText>
            </TextBox>
          </BGImage>
        </Parallax>
      </ImageSection>
      <TextGroupCol>
        <LargerDarkText>
          Our Deep Ecology retreats and workshops reconnect you with yourself,
          to your community, and to the earth.
        </LargerDarkText>
        <LargeDarkText>
          Reconnect through facilitated group exercises that acknowledge both
          gratitude for the beauty of our world, and despair of what is
          happening to our planet and to our society. This work helps you “see
          with new eyes” and go forward, re-empowered for the work ahead.
        </LargeDarkText>
      </TextGroupCol>
      <ImageSection>
        <Parallax blur={{ min: -15, max: 15 }} bgImage={Image02} strength={100}>
          <BGImage>
            <TextBox>
              <LightText>
                “I have got a tremendous amount out of the Deep Ecology
                workshops. They re evangelise me, from knowing to action… and
                bring freshness and change to my ways. It is not a one off
                workshop for me, I have attended at least six and will continue
                to attend.
                <br />
                At each one I learn something new and skip away pretty happy
                that we have this depth of presentation in Bellingen.” – Jo
              </LightText>
            </TextBox>
          </BGImage>
        </Parallax>
      </ImageSection>
      <TextBox>
        <MainHeading>ABOUT THE WORK THAT RECONNECTS</MainHeading>
        <TextDark>
          There are big shifts going on in the world – which can be fascinating,
          scary, and exciting all at the same time. Because it is important that
          we acknowledge these thoughts and emotions, and explore where they can
          lead us, we would like to invite you to join us for a conversation
          about what it means to be alive at this time on the planet.
          <br />
          <br />
          The Centre for Ecological Learning hosts regular Deep Ecology programs
          daylong workshops, study groups, and retreats. We also deliver
          programs for external organisations, including schools, local
          councils, and festivals.
          <br />
          <br />
          These events are a chance to connect to other community members,
          through conversation and group exercises that acknowledge both
          gratitude for the beauty of our world, and despair for what is
          happening to our planet. There is also space for co-creating positive
          actions for going forth in this time of upheaval.
          <br />
          <br />
          Our facilitators are trained as part of the international network
          established by eco-philosopher and elder Joanna Macy, who founded “The
          Work That Reconnects”.
          <br />
          <br />
        </TextDark>
      </TextBox>
      <SmallSlider images={SliderImages} />
      <TextBox>
        <Quote>
          <i>
            “Because the relationship between self and world is reciprocal, it
            is not a matter of first getting enlightened or saved and then
            acting. As we work to heal the Earth, the Earth heals us. No need to
            wait. As we care enough to take risks, we loosen the grip of ego and
            begin to come home to our true nature. For in the co-arising nature
            of things, the world itself, if we are bold enough to love it, acts
            through us.”
          </i>{" "}
          – <b>Joanna Macy</b>
        </Quote>
      </TextBox>
      <TextButton>
        <TextBox style={{ background: "#7fa875", borderRadius: "5px" }}>
          <a
            href="https://workthatreconnects.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <TextLight>More about the Work That Reconnects</TextLight>
          </a>
        </TextBox>
      </TextButton>

      <Footer />
    </PageContainer>
  );
};

export default DeepEcology;
