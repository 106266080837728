import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

import ProgressiveImage from "../components/progressiveImage";
import Footer from "../components/footer";

import Statement from "../images/Statement.png";
import StatementMini from "../images/Statement_mini.png";
// import Tree from "../images/tree-banner.jpg";
import Tree from "../images/tree-portrait.jpg";
import Web from "../images/web.png";
import WebMini from "../images/web_mini.png";

const Container = styled.div`
  display: block;
  margin: 0 auto;
  min-height: 80vh;
`;

const StatementBox = styled.div`
  width: 100%;
  display: block;
  margin: 30px auto;
  padding: 20px;
  background-image: url(${Tree});
  background-repeat: no-repeat;
  background-position: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const TextBox = styled.div`
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 20px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const LightText = styled.p`
  font-size: 1.5em;
  text-align: center;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  @media only screen and (min-width: 1000px) {
    font-size: 2em;
  }
`;

const ImageDiv = styled.div`
  width: 100%;
  display: block;
  margin: 0;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Image = styled.img`
  width: 100%;
  display: block;
  margin: 15px auto;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Governance = () => {
  return (
    <>
      <Container>
        <TextBox>
          {/* <ImageDiv>
            <Image
              src={Statement}
              placeholderSrc={StatementMini}
              alt="The Centre for Ecological Learning (CEL) supports people of all ages to experience Earth-based, immersive, educational experiences that deepen our relationship with ourselves and the natural world. These experiences nourish, inspire and equip people with the knowledge and skills to go forth and adopt more ecologically sustainable ways of liveing in their personal lives and local community."
            />
          </ImageDiv> */}
          <SubHeading>Our Mission Statement</SubHeading>
          <StatementBox>
            <LightText>
              The Centre for Ecological Learning (CEL) supports people of all
              ages to experience Earth-based, immersive, educational experiences
              that deepen our relationship with ourselves and the natural world.
              These experiences nourish, inspire and equip people with the
              knowledge and skills to go forth and adopt more ecologically
              sustainable ways of liveing in their personal lives and local
              community.
            </LightText>
          </StatementBox>
          <TextDark>
            The Principal Purpose of the Centre for Ecological Learning
            Incorporated (CEL) is for the provision of information and education
            about the natural environment, and for the promotion of ecologically
            sustainable principles.
          </TextDark>
          <TextDark>
            CEL is an incorporated not-for-profit organisation and is registered
            as a Charity with the Australian Charities and Not-for-profits
            Commission. CEL is listed on the Register of Environmental
            Organisations and has Deductible Gift Recipient (DGR) status. A
            public fund called the Ecological Learning Fund is maintained for
            the specific purpose of supporting CEL’s environmental objects and
            purposes.
          </TextDark>
          <TextDark>
            CEL is governed by a Board of Directors who are elected yearly and
            meet monthly with a focus on delivering our mission and meeting all
            legal and ethical requirements of the organisation. The Board is
            made up of three office bearer positions – Chairperson, Treasurer
            and Secretary – as well as three additional Ordinary members.
          </TextDark>
          <TextDark>
            Anyone can become an annual CEL member by joining via our website.
            CEL welcomes people of all cultures, genders, sexualities, and
            religions. Our goal is to work ecologically and ethically both in
            our programming and our governance. Please contact us if you would
            like to learn more, or if you would like to become involved!
          </TextDark>
        </TextBox>
        <ImageDiv>
          <ProgressiveImage
            src={Web}
            placeholderSrc={WebMini}
            alt="An intricate spiderweb dusted with sparkling dew."            
          ></ProgressiveImage>
        </ImageDiv>
      </Container>
      <Footer />
    </>
  );
};

export default Governance;
