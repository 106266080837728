import React, { useState } from "react";
import { menuTitles } from "./menuTitles";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";

import MenuItems from "./MenuItems";
import Logo from "./Logo";

import Index from "../../pages/index";
import Join from "../../pages/join";
import Subscribe from "../../pages/subscribe";
import Donate from "../../pages/donate";
import Temp from "../../pages/temp";
import NatureAdults from "../../pages/natureAdults";
import Community from "../../pages/community";
import DeepEcology from "../../pages/deepEcology";
import NatureYouths from "../../pages/natureYouths";
import Malaami from "../../pages/malaami";
import Team from "../../pages/team";
import About from "../../pages/about";
import Governance from "../../pages/governance";
import MADWalks from "../../pages/madwalks";

import LearningAlliance from "../../pages/learningAlliance";

const Bar = styled.div`
  width: 70vw;
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;

  position: -webkit-sticky;
  position: sticky;
  top: -1px;

  @media only screen and (max-width: 1100px) {
    width: 95vw;
  }
`;

const Nav = styled.nav`
  position: relative;
  height: 58px;
  display: block;
  float: right;
  padding-right: 2vw;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const BurgerNav = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const Menus = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
`;

const BurgerButton = styled.button`
  position: relative;
  left: 60vw;
  z-index: 10;
  background: transparent;
  border: none;
`;

const Navbar = () => {
  const [burgerOpen, setBurgerOpen] = useState(false);

  function handleToggle(){
    setBurgerOpen((prev) => !prev);
  };

  const MenuAnimation = keyframes`
    0% {max-width: 0%;}
  `;

  const BurgerMenu = styled.ul`
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: -16px;
    background: #fbfcf8;
    left: -40px;
    bottom: 0;
    height: 100vh;
    width: ${burgerOpen ? "100%" : "0"};
    animation-name: ${MenuAnimation};
    animation-duration: 0.5s;
    overflow: hidden;
    max-width: 290px;
    z-index: 9;
  `;

  return (
    <>
      <Bar>
        <Logo />
        <BurgerNav>
          <BurgerButton onClick={handleToggle}>
            {burgerOpen ? (
              <MdClose
                style={{ color: "black", width: "40px", height: "40px" }}
              />
            ) : (
              <FiMenu
                style={{ color: "black", width: "40px", height: "40px" }}
              />
            )}
          </BurgerButton>
          <BurgerMenu>
            {menuTitles.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  handleToggle={handleToggle}
                />
              );
            })}
          </BurgerMenu>
        </BurgerNav>
        <Nav>
          <Menus>
            {menuTitles.map((menu, index) => {
              const depthLevel = 0;
              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  handleToggle={handleToggle}
                />
              );
            })}
          </Menus>
        </Nav>
      </Bar>
      <Routes>
        <Route exact path="/" element={<Index />}></Route>
        <Route path="/donate" element={<Donate />}></Route>
        <Route path="/join" element={<Join />}></Route>
        <Route path="/subscribe" element={<Subscribe />}></Route>
        <Route path="/tempPage" element={<Temp />}></Route>
        <Route path="/learningAlliance" element={<LearningAlliance />}></Route>
        <Route path="/natureAdults" element={<NatureAdults />}></Route>
        <Route path="/bsla" element={<Community />}></Route>
        <Route path="/MADWalks" element={<MADWalks />}></Route>
        <Route path="/deepEcology" element={<DeepEcology />}></Route>
        <Route path="/natureYouths" element={<NatureYouths />}></Route>
        <Route path="/malaami" element={<Malaami />}></Route>
        <Route path="/team" element={<Team />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/governance" element={<Governance />}></Route>
      </Routes>
    </>
  );
};

export default Navbar;
