import React from "react";
import styled from "styled-components";

const ParallaxBgLge = styled.div`
  height: 40vh;
  width: 100vw;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  background-color: rgba(75, 75, 75, 0.5);

  @media only screen and (min-width: 480px) {
    height: 50vh;
  }
  @media only screen and (min-width: 768px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1800px) {
    height: 50vh;
  }
`;
export const ParallaxLge = ({ children }) => {
  return <ParallaxBgLge>{children}</ParallaxBgLge>;
};
