import React, { useState } from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";
import Modal from "@material-ui/core/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

const Container = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: 15px 0;

  @media only screen and (min-width: 750px) {
    width: 45%;
  }
  @media only screen and (min-width: 1100px) {
    width: 30%;
  }
`;

const DarkText = styled.p`
  font-size: 1.1em;
  text-align: center;
  margin: 1vh 1vw;
  color: #666666;
  max-width: 350px;
`;

const Pic = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 2px 2px 2px grey;

  @media only screen and (min-width: 750px) {
  }
  @media only screen and (min-width: 1100px) {
  }
`;

const LargeDarkText = styled.p`
  font-size: 1.7em;
  text-align: center;
  margin: 1vh 1vw;
  color: #666666;
`;

const ModalContainer = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  width: 90%;
  max-width: 380px;
  max-height: 80vh;
  background: white;
  overflow: auto;
`;

const CloseButton = styled.button`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  float: right;
`;

const TeamMember = ({ member }) => {
  const [open, setOpen] = useState(false);
  const [pass, setPass] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Pic src={member.pic} onClick={handleOpen} />
      <LargeDarkText onClick={handleOpen}>{member.name}</LargeDarkText>
      <DarkText onClick={handleOpen}>{member.titles}</DarkText>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContainer>
          <CloseButton onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} />
          </CloseButton>
          <LargeDarkText>{member.name}</LargeDarkText>
          <TextDark>
            <b>
              <i>{member.titles}</i>
            </b>
          </TextDark>
          <TextDark onClick={handleClose}>{member.bio.map((text) => <p>{text}</p>)}</TextDark>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default TeamMember;
