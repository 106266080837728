import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";
import { Link } from "react-scroll";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  SmallTextLight,
} from "../components/siteStyling/textStyling";

import Footer from "../components/footer";
import SmallSlider from "../components/slider/smallSlider";
import ProgressiveImage from "../components/progressiveImage";
import Mountains from "../images/LearningAlliancePage/mountains.jpeg"

import Weeds from "../images/LearningAlliancePage/weeds-walk-6.jpg";
import WeedsMini from "../images/LearningAlliancePage/weeds-walk-6_mini.png";
import BSLABanner from "../images/LearningAlliancePage/BSLA-banner.jpg";
import DEcology from "../images/LearningAlliancePage/Deep-Ecology.jpg";
import slide01 from "../images/LearningAlliancePage/01.jpg";
import slide02 from "../images/LearningAlliancePage/02.jpeg";
import slide03 from "../images/LearningAlliancePage/03.png";
import slide05 from "../images/LearningAlliancePage/05.jpg";
import slide06 from "../images/LearningAlliancePage/06.jpg";

import fbLogo from "../images/fb_logo.png";

// import BCAC from "../images/LearningAlliancePage/BCAC.png";
// import BCoC from "../images/LearningAlliancePage/BCoC.jpg";
// import SeedSavers from "../images/LearningAlliancePage/Bellingen-Seed-Savers.png";
// import BSEA from "../images/LearningAlliancePage/BSEA.png";
// import BUL from "../images/LearningAlliancePage/BUL.png";
// import Cascade from "../images/LearningAlliancePage/Cascade.jpg";
// import CCCC from "../images/LearningAlliancePage/CCCC.jpg";
// import Northbank from "../images/LearningAlliancePage/northbank.jpg";
// import OzGreen from "../images/LearningAlliancePage/OzGreen.png";
// import Tallowood from "../images/LearningAlliancePage/Tallowood.jpg";
// import UnitingEarth from "../images/LearningAlliancePage/Uniting-Earth.jpg";
// import CEL from "../images/LearningAlliancePage/logo.png";
const SliderImages = [slide01, slide02, slide03, slide05, slide06];

const ImageSection = styled.div`
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Banner = styled.img`
  max-width: 100vw;
  height: auto;
`;

const TextBox = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const TextGroupRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin: 0 10vw;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const TextRow = styled.div`
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  @media only screen and (max-width: 750px) {
    width: 99%;
  }
`;

const BGImage = styled.div`
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    height: 650px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const LightText = styled.p`
  font-size: 1.5em;
  text-align: center;
  color: white;
  @media only screen and (min-width: 1000px) {
    font-size: 2em;
  }
`;

const DarkText = styled.p`
  font-size: 1.2em;
  text-align: left;
  margin: 5px 5px;
  color: #666666;
`;

const TopImage = styled(SectionContainerLge)`
  @media only screen and (min-width: 480px) {
    height: 50vh;
  }
  @media only screen and (min-width: 768px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 50vh;
  }
  @media only screen and (min-width: 1800px) {
    height: 50vh;
  }
`;

const SmallHeading = styled.div`
  text-transform: uppercase;
  font-size: 1.7em;
  text-align: center;
  color: #7fa875;
  margin-top: 15px;

  @media only screen and (max-width: 750px) {
  }
`;

const Bold = styled.h3`
  font-size: 1.3em;
  text-align: center;
  margin: 1vh 2vw;
  color: #666666;
  text-transform: uppercase;
`;

const List = styled.ul`
  padding: 0 15px;
  list-style-type: none;
  font-size: 1em;
  text-align: centre;
  margin: 1vh 1vw;
  color: #666666;
`;
const ListItem = styled.li`
  padding-top: 5px;
  list-style-type: none;
`;

const StatementBox = styled.div`
  background: rgba(144, 198, 149, 0.85);
  margin: 10px;
  padding: 30px;
  border-radius: 5px;
  font-size: 1.5em;
  color: black;
  font-weight: bold;
  max-width: 300px;
  height: 200px;
`;

const StatementSection = styled.div`
display: flex;
flex-flow: column wrap;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
  flex-flow: row wrap;
  width: 650px;
}
@media only screen and (min-width: 1024px) {
}
@media only screen and (min-width: 1800px) {
}
`;

const LinkStyle = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const LearningAlliance = () => {
  return (
    <PageContainer>
      <TextBox>
        <MainHeading>Bellingen Shire Learning Alliance</MainHeading>
      </TextBox>
      <ImageSection>
        <Banner
          src={BSLABanner}
          alt="Bellingen Shire Learning Alliance banner"
        />
      </ImageSection>
      <ImageSection>
        <Parallax blur={{ min: -15, max: 15 }} bgImage={Weeds} strength={100}>
          <BGImage>
            <TextBox>
              <LightText>We are nature</LightText>
            </TextBox>
          </BGImage>
        </Parallax>
      </ImageSection>
      <TextGroupRow>
        <TextDark>
          The Bellingen Shire Learning Alliance (BSLA), is a network of local
          groups who all have a core value of sustainability – ecological,
          social, and economic. This Shire-wide alliance works collectively and
          independently to create a culture of perpetual learning, sustainable
          action and ecological balance. We also provide opportunities for
          community engagement on sustainability matters through workshops and
          events.
          <br />
          <br />
          The BSLA is housed in the Bellingen Sustainability Centre, 6A Church
          St, Bellingen. The centre is a meeting and co-working space for
          members groups. Quarterly networking meet-ups are held to share
          updates and discuss collaboration opportunities.
          <br />
          <br />
          To find out more about becoming a member or how the BSLA can support
          your sustainability goals please contact{" "}
          <a href="mailto: bellingenshirelearningalliance@gmail.com">
            <LinkStyle>bellingenshirelearningalliance@gmail.com</LinkStyle>
          </a>
          <br />
          <br />
        </TextDark>
      </TextGroupRow>

      {/* <TextGroupRow>
        <TextRow>
          <SmallHeading>MEETING & COWORKING SPACE</SmallHeading>
          <TextDark>
            Representatives from the Bellingen Shire Learning Alliance member
            groups meet quarterly at the <b>Bellingen Sustainability Centre</b>{" "}
            where they share updates and discuss events and collaboration
            opportunities. The Bellingen Sustainability Centre is also a place
            where member groups meet and have working spaces.{" "}
            <Link
              to="footer"
              spy={true}
              smooth={true}
              style={{ textDecoration: "underline" }}
            >
              Contact us
            </Link>{" "}
            for more info.
          </TextDark>
        </TextRow>
      </TextGroupRow> */}
      <SmallSlider images={SliderImages} />
      <SmallHeading>
        <br />
        BSLA MEMBER GROUPS
      </SmallHeading>
      <TextBox>
        <List>
          <ListItem>&#8226; Bellingen Activist Network</ListItem>
          <ListItem>
            &#8226; Bellingen Community Arts Council/Mixed Up Arts
          </ListItem>
          <ListItem>&#8226; Bellingen Readers & Writers Festival</ListItem>
          <ListItem>&#8226; Bellingen Seed Savers</ListItem>
          <ListItem>&#8226; Bellinger Landcare</ListItem>
          <ListItem>
            &#8226; Bellinger River and Neighbourhood Cooperative Housing
            (BRANCH)
          </ListItem>
          <ListItem>&#8226; Centre for Ecological Learning</ListItem>
          <ListItem>&#8226; Coffee 4 Climate</ListItem>
          <ListItem>&#8226; Forest Advocacy Ministry</ListItem>
          <ListItem>&#8226; Fractal Economy Cooperative</ListItem>
          <ListItem>&#8226; Housing Matters Action Groupn</ListItem>
          <ListItem>&#8226; Neighbourhood Care Network</ListItem>
          <ListItem>&#8226; Ngaarrila Preschool</ListItem>
          <ListItem>&#8226; Northbank Community Garden</ListItem>
          <ListItem>&#8226; OzGreen</ListItem>
          <ListItem>&#8226; Tallowwood Sangha</ListItem>
          <ListItem>&#8226; Yurruungga Aboriginal Corporation (YAC)</ListItem>
        </List>
        <TextDark>
          The Bellingen Shire Learning Alliance and the Sustainability Centre
          are managed by the Centre For Ecological Learning.
        </TextDark>
      </TextBox>

      <ImageSection>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={DEcology}
          strength={100}
        >
          <TopImage />
        </Parallax>
      </ImageSection>
      <TextBox>
        <br />
        <br />
        <SmallHeading>What are the aims of the BSLA?</SmallHeading>
        <br />
        <br />
        <ImageSection>
          <Parallax blur={{ min: -15, max: 15 }} bgImage={Mountains} strength={100}>
            <BGImage>
              <StatementSection>
                <StatementBox>To create a strong platform for the gathering and sharing of
                  resources and skills.</StatementBox>
                <StatementBox>To encourage and build an emotional home for change-makers.</StatementBox>
                <StatementBox>To foster the emergence and growth of a green economy.</StatementBox>
                <StatementBox>Develop into a recognised leader in education and action
                  for sustainability.</StatementBox>
              </StatementSection>
            </BGImage>
          </Parallax>
        </ImageSection>
      </TextBox>
      {/* <TextBox>
        <SmallHeading>Mission</SmallHeading>
        <DarkText>
          The Bellingen Shire Learning Alliance is a Shire wide alliance working
          collectively and independently to create a culture of lifelong
          learning for sustainability and ecological balance.
        </DarkText>
        <SmallHeading>Aims</SmallHeading>
        <List>
          <ListItem>
            &#8226; To create a strong platform for the gathering and sharing of
            resources and skills.
          </ListItem>
          <ListItem>
            &#8226; To develop into a recognised leader in education and action
            for sustainability.
          </ListItem>
          <ListItem>
            &#8226; To foster the emergence and growth of a green economy.
          </ListItem>
          <ListItem>
            &#8226; To encourage and build an emotional home for change-makers.
          </ListItem>
        </List>
        <SmallHeading>Objectives</SmallHeading>
        <Bold>
          We will create a strong platform for the gathering and sharing of
          resources and skills by:
        </Bold>
        <List>
          <ListItem>
            &#8226; Establishing a physical administrative home base, where
            local sustainability projects are profiled.
          </ListItem>
          <ListItem>
            &#8226; Creating resilient and effective working relationships
            within the Alliance.
          </ListItem>
          <ListItem>
            &#8226; Continuing to build a culture of inclusivity and diversity.
          </ListItem>
          <ListItem>
            &#8226; Creating an independent resource stream to support our work.
          </ListItem>
        </List>
        <Bold>
          We will develop into a recognised leader in education and action for
          sustainability by:
        </Bold>
        <List>
          <ListItem>
            &#8226; Sharing our knowledge and actions locally, nationally and
            globally.
          </ListItem>
          <ListItem>
            &#8226; Attracting people to the Shire to learn about sustainability
          </ListItem>
        </List>
        <Bold>
          We will foster the emergence and growth of a green economy by:
        </Bold>
        <List>
          <ListItem>
            &#8226; Creating a culture of localisation in our Shire Promoting
            the continuation of the “greening” of Shire businesses.
          </ListItem>
          <ListItem>
            &#8226; Creating a culture of localisation in our Shire Promoting
            the continuation of the “greening” of Shire businesses.
          </ListItem>
          <ListItem>
            &#8226; Attracting, establishing, and promoting “green” business in
            the Shire.
          </ListItem>
          <ListItem>
            &#8226; Create meaningful work for Alliance members
          </ListItem>
        </List>
        <Bold>
          We will encourage and build an emotional home for change-makers by:
        </Bold>
        <List>
          <ListItem>
            &#8226; Creating a community of like-minded, skilled, aware people
            who are committed to learning for change
          </ListItem>
          <ListItem>
            &#8226; Establishing a physical hub that offers a nurturing and safe
            space
          </ListItem>
          <ListItem>
            &#8226; Offering opportunities for strengthening emotional personal
            community resilience.
          </ListItem>
        </List>
      </TextBox> */}
      <TextBox>
        <DarkText>
          Follow the Bellingen Sustainability Centre on Facebook
        </DarkText>
        <a
          href="https://www.facebook.com/bellingensustainabilitycentre"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={fbLogo} alt="facebook logo" height="60px"></img>
        </a>
      </TextBox>
    </PageContainer>
  );
};

export default LearningAlliance;
