import React from "react";
// import { useWindowDimensions } from "react-native";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

// import { data } from "./data";
import nature from "../../images/Nature-Academy.jpg";
import deepEcology from "../../images/deep-ecology.jpg";
import learningAlliance from "../../images/learning-alliance.jpg";
import activities from "../../images/activities.jpg";

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 98vw;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 50vw;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SliderImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  object-fit: cover;

  @media only screen and (min-width: 480px) {
    width: 70%;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 50%;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SmallSlider = (images) => {
  return (
    <Container>
      <AliceCarousel
        autoPlay
        infinite={true}
        autoPlayInterval="4000"
        disableButtonsControls
        disableDotsControls
      >
        {images.images.map((image, index) => {
          return <SliderImage src={image} key={index} />;
        })}
      </AliceCarousel>
    </Container>
  );
};

export default SmallSlider;
