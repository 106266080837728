import React from "react";
import styled from "styled-components";

const ContainerPage = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;

  @media only screen and (max-width: 1100px) {
  }

  @media only screen and (max-width: 750px) {
  }
`;
export const PageContainer = ({ children }) => {
  return <ContainerPage>{children}</ContainerPage>;
};
