import React from "react";
import styled from "styled-components";
import "../App.css";
import ProgressiveImage from "../components/progressiveImage";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";

import {
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
} from "../components/siteStyling/textStyling";

import Footer from "../components/footer";

import Gumnut from "../images/NatureAdults/gumnut.jpg";
import GumnutMini from "../images/NatureAdults/gumnut_mini.jpg";
import Balance from "../images/NatureAdults/balance.jpg";
import BalanceMini from "../images/NatureAdults/balance_mini.jpg";
import HeaderBG from "../images/NatureAdults/Nature-Academy.jpg";
import SectBG from "../images/NatureAdults/background.png";

import Boy from "../images/NatureAdults/boy.png";
import Emotions from "../images/NatureAdults/Emotions.png";
import Mindful from "../images/NatureAdults/Mindful.png";
import Resilient from "../images/NatureAdults/Resilient.png";
import Strong from "../images/NatureAdults/Strong.png";

const Header = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  background-image: url(${HeaderBG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    height: 300px;
  }
`;

const PageTitle = styled.h1`
  text-transform: uppercase;
  text-decoration: bold;
  font-size: 2em;
  text-align: center;
  color: #7fa875;
  margin-top: 5vh;
`;

const ParallaxContainer = styled.div`
  width: 2000px;
  max-width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {    
    width: 98vw;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const PageSection = styled.div`
  background-color: teal;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const SectionTitle = styled.h3`
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.7em;
  padding: 20px 10px;
  margin: 0 0;
`;
const SubSectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    flex-flow: row nowrap;
  }
  @media only screen and (min-width: 1024px) {
  }  
  @media only screen and (min-width: 1600px) {
    max-width: 1200px;
  }
`;
const SubSection = styled.div`
  margin: 20px 0;
  padding-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    height: 400px;
    justify-content: space-around;
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 0px;
    margin: 0px 0;
    height: 300px;
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Icon = styled.img`
  display: flex;
  align-self: flex-start;
  margin: 15px auto;
`;
const SmallTitle = styled.h4`
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4em;
  padding: 0px 10px;
  margin: 0 0;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    padding: 0px 5px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Description = styled.p`
  color: white;
  text-align: center;
  font-size: 1.1em;
  padding: 0px 10px;
  margin: 0 0;
`;
const Disclaimer = styled.p`
  color: white;
  text-align: center;
  font-size: 0.6em;
  padding: 0px 10px;
`;

const TextBox = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const Image = styled.div`
  max-width: 100%;
  display: block;
  margin: 15px auto;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const List = styled.ul`
  padding: 0 15px;
  list-style-type: none;
  font-size: 1.2em;
  text-align: center;
  margin: 1vh 1vw;
  color: #666666;
`;
const ListItem = styled.li`
  padding-top: 5px;
  list-style-type: none;
`;

const Link = styled.a`
  color: #2affe1;
  text-decoration: underline;
  padding: 0 5px;
`;

const NatureAdults = () => {
  return (
    <PageContainer>
      <Header />
      <TextBox>
        <PageTitle>NATURE CONNECTION MENTORING TRAINING</PageTitle>
        <SubHeading>
          LEARN TO CREATE WILD EXPERIENCES FOR KIDS IN YOUR LOCAL ‘OUTBACK’.
        </SubHeading>
        <LargeDarkText>
          <b>
            Our in-person or online training will provide you with the
            confidence to share your local wild places with children and young
            people.
          </b>
        </LargeDarkText>
        <TextDark>
          Whether you’re an early childhood educator, primary school teacher,
          parent, or carer – our courses will enable you to{" "}
          <b>
            mentor children and young people to feel at home in the natural
            environment.
          </b>{" "}
          But just as importantly, you also get to{" "}
          <b>
            learn about and embrace your own unique local landscape – both inner
            and outer!
          </b>{" "}
          – in order to share this love of place with children, your community,
          and beyond.
          <br />
          <br />
          We know that there are rising levels of anxiety, stress, depression,
          ADHD, and poor physical strength in children.* We also know that time
          in nature is an excellent antidote.**
        </TextDark>
      </TextBox>
      <LargeDarkText>
        <b>Are you ready to be a part of the nature play movement?</b>
      </LargeDarkText>
      <PageSection>
        <Parallax
          bgImage={SectBG}
          bgImageAlt="background colours"
          strength={200}
        >
          <SectionTitle>Benefits of Nature Play</SectionTitle>
          <ParallaxContainer>
            <SubSectionContainer>
              <SubSection>
                <Icon src={Mindful} alt="" />
                <SmallTitle>ENHANCED MINDFULNESS</SmallTitle>
                <Description>
                  with an increase in focus & decrease in ADHD symptoms
                </Description>
              </SubSection>
              <SubSection>
                <Icon src={Boy} alt="" />
                <SmallTitle>INCREASED CONFIDENCE</SmallTitle>
                <Description>
                  through problem solving skills, imaginative play and
                  creativity.
                </Description>
              </SubSection>
              <SubSection>
                <Icon src={Strong} alt="" />
                <SmallTitle>GREATER PHYSICAL STRENGTH</SmallTitle>
                <Description>
                  including greater core strength, enhanced balance and
                  increased fine motor skills.
                </Description>
              </SubSection>
              <SubSection>
                <Icon src={Emotions} alt="" />
                <SmallTitle>STRONGER EMOTIONAL FOUNDATIONS</SmallTitle>
                <Description>
                  with greater impulse control & sensory regulation
                </Description>
              </SubSection>
              <SubSection>
                <Icon src={Resilient} alt="" />
                <SmallTitle>IMPROVED RESILIENCE</SmallTitle>
                <Description>
                  through awareness of risk, ability to persevere, overcome
                  challenges and discomfort.
                </Description>
              </SubSection>
            </SubSectionContainer>
          </ParallaxContainer>
          <Disclaimer>
            *Climbing Trees: Getting Aussie Kids Back Outdoors – Planet Ark –{" "}
            <Link
              href="https://bit.ly/2yC1qIT"
              target="_blank"
              rel="noreferrer noopener"
            >
              Source
            </Link>
          </Disclaimer>
          <Disclaimer>
            ** From muddy hands and dirty faces… to higher grades and happy
            places. Outdoor learning and play at schools around the world. Cath
            Prisk and Dr Harry Cusworth –{" "}
            <Link
              href="http://bit.ly/2ZDausW"
              target="_blank"
              rel="noreferrer noopener"
            >
              Source
            </Link>
          </Disclaimer>
        </Parallax>
      </PageSection>
      <TextBox>
        <SubHeading>NATURE PLAY MENTOR TRAINING</SubHeading>
        <TextDark>
          Our in-person or online training provides interactive experiential
          learning and guided reflection to give you the skills necessary to
          mentor children and young people in the natural environment. The
          journey begins with a personal “re-wilding” to help you build your
          confidence and connection with both your inner and your outer local
          landscapes.
          <br />
          <br />
          You are then guided to move out into your local terrain to learn its
          special secrets. Finally, we bring it all together to offer a solid
          toolbox of pedagogies and strategies, and more sector specific
          applications of nature play.
          <br />
          <br /> Throughout the course, you have the opportunity to not only
          rebuild your sense of awe and wonder for the natural world around you,
          but to learn specific skills that will help you share this love with
          children and young people.
        </TextDark>
        <Image>
          <ProgressiveImage
            src={Gumnut}
            placeholderSrc={GumnutMini}
            alt="Close up of child's toy raft crafted from natural items such as sticks and gum nuts."
          />
        </Image>
        <LargeDarkText>WHAT YOU’LL LEARN</LargeDarkText>
        <List>
          <ListItem>&#8226; Nature journalling</ListItem>
          <ListItem>&#8226; Mindfulness in nature</ListItem>
          <ListItem>
            &#8226; Theoretical frameworks underpinning Nature Play
          </ListItem>
          <ListItem>&#8226; Program design and planning</ListItem>
          <ListItem>&#8226; Identifying play urges in young children</ListItem>
          <ListItem>&#8226; Reading landscapes & wild spaces</ListItem>
          <ListItem>&#8226; How to set up a base camp</ListItem>
          <ListItem>&#8226; Conducting risk assessments</ListItem>
          <ListItem>&#8226; Appropriate tool use for children</ListItem>
          <ListItem>&#8226; Introduction to bird language</ListItem>
          <ListItem>&#8226; Introduction to animal tracking</ListItem>
        </List>
      </TextBox>
      <Image>
        <ProgressiveImage
          src={Balance}
          placeholderSrc={BalanceMini}
          alt="Close up of child's toy raft crafted from natural items such as sticks and gum nuts."
        />
      </Image>
      <TextBox>
        <LargeDarkText>WHAT PAST PARTICIPANTS SAY</LargeDarkText>
        <TextDark>
          <i>
            “This course is for anyone who loves the outdoors and exploring
            their own relationships with the ‘natural’ world. It is also for
            anyone dismayed at the rapid changes happening to the beloved
            ecosystems around them and wondering how they can simultaneously
            deepen their understanding of what we call ‘Nature’ and help guide
            children to find their own relationship with it.”
            <br />
            <br />
            “The Nature Academy training has been an amazing tool to increase my
            level of awareness and preparation to be a nature mentor. The
            structure of the course is very flexible and reasonable. The weekly
            Zoom sessions with all the students and the coordinators were
            immensely valuable. Thanks so much for a great experience of
            training and personal growth!”
            <br />
            <br />
            “In these times of rapid ecological change, when so many of us are
            feeling distressed and unsure about what we can do- this course
            offers a solution that is both philosophical and highly practical-
            reconnecting ourselves and our children with the natural world.”
          </i>
        </TextDark>
      </TextBox>
      <Footer />
    </PageContainer>
  );
};

export default NatureAdults;
