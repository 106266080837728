import React from "react";
import styled from "styled-components";
import { Parallax } from "react-parallax";
import "../App.css";

import { PageContainer } from "../components/siteStyling/containerPage";

import {
  SubHeadingLight,
  LargeLightText,
} from "../components/siteStyling/textStyling";
import { Button } from "../components/siteStyling/button";

import pic01 from "../images/join.jpg";

import Footer from "../components/footer";

const Wrap = styled.div`
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
    height: 50vh;
  }
  @media only screen and (min-width: 768px) {
    height: 70vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 70vh;
  }
  @media only screen and (min-width: 1800px) {
    height: 60vh;
  }
`;

const ParallaxContainer = styled.div`
  height: 80vh;
  width: 100vw;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  background-color: rgba(75, 75, 75, 0.5);

  @media only screen and (min-width: 480px) {
    height: 50vh;
  }
  @media only screen and (min-width: 768px) {
    height: 70vh;
  }
  @media only screen and (min-width: 1024px) {
    height: 70vh;
  }
  @media only screen and (min-width: 1800px) {
    height: 60vh;
  }
`;

const Join = () => {
  return (
    <PageContainer>
      <Wrap>
        <Parallax blur={{ min: -15, max: 15 }} bgImage={pic01} strength={200}>
          <ParallaxContainer>
            <SubHeadingLight>join the cel family</SubHeadingLight>
            <LargeLightText>become a CEL member</LargeLightText>
            <Button>
              <a
                href="https://docs.google.com/forms/d/1IkmZsq-bMRUYYUdfNaIbF9kRjiEX0uOlk-wpaNDGcKY/viewform?edit_requested=true"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Now
              </a>
            </Button>
          </ParallaxContainer>
        </Parallax>
      </Wrap>
      <Footer />
    </PageContainer>
  );
};

export default Join;
