import React from "react";
import styled from "styled-components";
import "../App.css";
import ProgressiveImage from "../components/progressiveImage";
import { Parallax } from "react-parallax";

// import { PageContainer } from "../components/siteStyling/containerPage";

import {
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  // TextDark,
} from "../components/siteStyling/textStyling";

import YoutubeEmbed from "../components/youtubeEmbed";
import Footer from "../components/footer";

import LogoImg from "../images/Malaami/logo.png";
import BannerBG from "../images/Malaami/banner.jpg";
import Dancers1 from "../images/Malaami/dancers1.jpg";
import Dancers1Mini from "../images/Malaami/dancers1_mini.jpg";
import Dancers2 from "../images/Malaami/dancers2.jpg";
import Dancers2Mini from "../images/Malaami/dancers2_mini.jpg";
import Panel2 from "../images/Malaami/panel2.jpg";
import Panel2Mini from "../images/Malaami/panel2_mini.jpg";
import Talk1 from "../images/Malaami/talk1.jpg";
import Talk2 from "../images/Malaami/talk2.jpg";
import Talk2Mini from "../images/Malaami/talk2_mini.jpg";
import Talk3 from "../images/Malaami/talk3.jpg";
import Smoke from "../images/Malaami/smoke.jpg";
import SmokeMini from "../images/Malaami/smoke_mini.jpg";
import Text from "../images/Malaami/text.jpg";
import TextMini from "../images/Malaami/text_mini.jpg";

import Gumnut from "../images/NatureAdults/gumnut.jpg";
import GumnutMini from "../images/NatureAdults/gumnut_mini.jpg";
import Balance from "../images/NatureAdults/balance.jpg";
import BalanceMini from "../images/NatureAdults/balance_mini.jpg";

import SectBG from "../images/NatureAdults/background.png";

import Boy from "../images/NatureAdults/boy.png";
import Emotions from "../images/NatureAdults/Emotions.png";
import Mindful from "../images/NatureAdults/Mindful.png";
import Resilient from "../images/NatureAdults/Resilient.png";
import Strong from "../images/NatureAdults/Strong.png";

const Header = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-size: cover;

  background-image: url(${BannerBG});
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    height: 300px;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const PageTitle = styled.h1`
  text-transform: uppercase;
  text-decoration: bold;
  font-size: 2em;
  text-align: center;
  color: #7fa875;
  margin-top: 2vh;
`;

const ParallaxContainer = styled.div`
  width: 2000px;
  max-width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    width: 98vw;
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const PageSection = styled.div`
  background-color: teal;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const SectionTitle = styled.h3`
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.7em;
  padding: 20px 10px;
  margin: 0 0;
`;
const SubSectionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    flex-flow: row nowrap;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    max-width: 1200px;
  }
`;
const SubSection = styled.div`
  margin: 20px 0;
  padding-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    height: 400px;
    justify-content: space-around;
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 0px;
    margin: 0px 0;
    height: 300px;
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Icon = styled.img`
  display: flex;
  align-self: flex-start;
  margin: 15px auto;
`;
const SmallTitle = styled.h4`
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4em;
  padding: 0px 10px;
  margin: 0 0;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    padding: 0px 5px;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;
const Description = styled.p`
  color: white;
  text-align: center;
  font-size: 1.1em;
  padding: 0px 10px;
  margin: 0 0;
`;
const Disclaimer = styled.p`
  color: white;
  text-align: center;
  font-size: 0.6em;
  padding: 0px 10px;
`;

const TextBox = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1600px) {    
  }
`;

const TextDark = styled.p`
  font-size: 1.1em;
  text-align: left;
  margin: 5px 5px;
  color: #666666;
`;

const Image = styled.div`
  max-width: 95vw;
  display: block;
  margin: 5px auto;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    max-width: 75vw;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    max-width: 55vw;
  }
`;

const Img = styled.img`
  clip-path: inset(0);
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Logo = styled.img`
  max-width: 100%;
  display: block;
  margin: 5px auto;
  height: 300px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const List = styled.ul`
  padding: 0 15px;
  list-style-type: none;
  font-size: 1.2em;
  text-align: center;
  margin: 1vh 1vw;
  color: #666666;
`;
const ListItem = styled.li`
  padding-top: 5px;
  list-style-type: none;
`;

const Link = styled.a`
  color: #2affe1;
  text-decoration: underline;
  padding: 0 5px;
`;

const LinkStyle = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Malaami = () => {
  return (
    <PageContainer>
      <Header>
        <Logo
          src={LogoImg}
          alt="Malaami project logo with subtitle 'return to origin'"
        />
      </Header>
      <PageTitle>The Malaami Project - Back to Origin</PageTitle>


      <YoutubeEmbed embedId="OmljGNAtbf0" />
      {/* <Image>
          <Img src={Smoke} alt="Smoke ceremony" />
        </Image> */}
      <Image>
        <ProgressiveImage
          src={Smoke}
          placeholderSrc={SmokeMini}
          alt="A traditional smoke ceremony performed on guests of the talk."
        />
      </Image>
      <TextBox>
        <TextDark>
          <b>
            The Malaami Project was launched at the end of March 2023 when over
            150 people gathered at the Bellingen Showground to learn from
            Country via culture and knowledge sharing from Gumbaynggirr elders
            and changemakers, and then 60 people continued the work the next day
            to collectively vision together a more balanced and resilient future
            for the Bellingen Shire/Gumbaynggirr country. Thanks to a
            Reconnecting Regional Communities grant from Bellingen Shire
            Council, the Centre for Ecological Learning was able to facilitate a
            process of learning about the original culture of this land, and
            then visioning a balanced and resilient future for the Bellingen
            Shire, grounded in Gumbaynggirr ways of knowing and being.
          </b>
        </TextDark>
        <TextDark>
          Please enjoy these beautiful photos of the launch event, photographed by Amber of <a
            href="https://wayilacreative.pixieset.com/malaami/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <LinkStyle>Wayila Creative</LinkStyle>
          </a>.
        </TextDark>
      </TextBox>
      <Image>
        {/* <Img src={Dancers1} alt="A traditional dance group pose outside." /> */}
        <ProgressiveImage
          src={Dancers1}
          placeholderSrc={Dancers1Mini}
          alt="A traditional dance group pose outside."
        />
      </Image>
      <SubHeading>
        <b>Malaami Learning from Country panel discussions</b>
      </SubHeading>
      <TextBox>
        <TextDark>
          See the below videos on our{" "}
          <a
            href="https://www.youtube.com/@centreforecologicallearning"
            target="_blank"
            rel="noreferrer noopener"
          >
            <LinkStyle>Youtube channel</LinkStyle>
          </a>
          .
        </TextDark>
      </TextBox>
      <YoutubeEmbed embedId="-b3krtidCAw" />
      <YoutubeEmbed embedId="GkpF8wSj7BI" />
      <YoutubeEmbed embedId="zRFrhHr752A" />
      <TextBox>
        <TextDark>
          The Malaami Conversation series and other events will continue to
          centre Gumbayngirr culture as we work to malaami (“return to origin”)
          and support a life-sustaining future for all beings. Please contact us at <a
            href="mailto: info@cel.org.au"
          >
            <LinkStyle>info@cel.org.au</LinkStyle>
          </a> for more information and to get involved.
        </TextDark>
      </TextBox>
      <Image>
        {/* <Img src={Talk2} alt="People talking together" /> */}
        <ProgressiveImage
          src={Talk2}
          placeholderSrc={Talk2Mini}
          alt="People talking together"
        />
      </Image>
      <Image>
        {/* <Img src={Panel2} alt="A discussion panel" /> */}
        <ProgressiveImage
          src={Panel2}
          placeholderSrc={Panel2Mini}
          alt="A discussion panel"
        />
      </Image>
      <Image>
        {/* <Img src={Dancers2} alt="A dancing group." /> */}
        <ProgressiveImage
          src={Dancers2}
          placeholderSrc={Dancers2Mini}
          alt="A traditional dancing group."
        />
      </Image>
      <Image>
        {/* <Img
          src={Text}
          alt="A piece of paper with various text messages written by different people."
        /> */}
        <ProgressiveImage
          src={Text}
          placeholderSrc={TextMini}
          alt="A piece of paper with various text messages written by different people."
        />
      </Image>

      <Footer />
    </PageContainer>
  );
};

export default Malaami;
