import React from "react";
import styled from "styled-components";

const ContainerText = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 5vh 0 5vh 0;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1800px) {
  }
`;
export const TextSection = ({ children }) => {
  return <ContainerText>{children}</ContainerText>;
};