import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";
import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

import TeamMember from "../components/teamMember";
import Footer from "../components/footer";

import AlisonPic from "../images/TeamPage/Alison.jpg";
import AndrewPic from "../images/TeamPage/Andrew.jpg";
import BennPic from "../images/TeamPage/Benn.jpg";
import ChrisPic from "../images/TeamPage/ChrisP.jpg";
import EmilyPic from "../images/TeamPage/Emily.jpg";
import ErinPic from "../images/TeamPage/Erin.jpg";
import JanePic from "../images/TeamPage/Jane.jpg";
import JasonPic from "../images/TeamPage/Jason.jpg";
import LisaPic from "../images/TeamPage/Lisa.jpg";
import MarkGPic from "../images/TeamPage/MarkG.jpg";
import MarkTPic from "../images/TeamPage/MarkT.jpg";
import OliviaPic from "../images/TeamPage/Olivia.jpg";
import TanyaPic from "../images/TeamPage/Tanya.jpg";
import ZiannaPic from "../images/TeamPage/Zianna-og.jpg";

const Alison = {
  name: "Alison Heeley",
  pic: AlisonPic,
  titles: "Community Education facilitator",
  bio: [
    "Alison is passionate about building local community food resilience, as part of her broader commitment to climate change mitigation and adaptation.",
    "She currently works with Mission Australia Housing in their Community Development team, setting up and supporting community gardens in social housing settings. Alison is the current President of the Northbank Community Gardens and also works for OzGreen as a co-ordinator in their Resilient Communities program - she keeps busy!",
    "Past CEL projects facilitated by Alison have included Waste Not Bellingen, MidWaste workshops, and setting up the Bellofoodbox as part of Transition Bellingen.",
  ],
};
const Andrew = {
  name: "Andrew Turbill",
  pic: AndrewPic,
  titles: "Nature Connection mentor",
  bio: [
    "Andrew is a founding member of Bellingen EYE and the Centre for Ecological Learning, and has been a regular and popular facilitator at our camps and bushwalks, sharing his knowledge of bird language, climate change science, naturalist observations and bushcraft skills (as well as his excellent sense of humour) with young people and adults.",
    "Andrew is an environmental educator, wildlife naturalist and natural history writer with a professional career in conservation land management, environmental education and science writing, both as a private consultant, and with the National Parks and Wildlife Service, for more than two decades.",
    "He is also the owner of the website Andrew the Bird Guy [LINK], a site dedicated to showing people how paying attention to the language, behaviour and quirky personalities of birds offers us an ideal gateway to deeper nature connection. ",
  ],
};
const Benn = {
  name: "Benn Wolhuter",
  pic: BennPic,
  titles: "Board Member and Youth Activities Facilitator",
  bio: [
    "Benn has worked in ecological education for over 20 years. He has a Bachelor of Education from Southern Cross University and a Bachelor of Environmental Science from Wollongong University. A year living and teaching in rural Cambodia helped him to see the value of positive, practical approaches and inspired him to complete a Permaculture Design Certificate in 2003.",
    "Benn has experience in youth and community work, school gardens, mentoring and personal development. He takes this practical, socio-ecological approach into his current teaching roles as a science teacher (at Allegra School Coffs Harbour) and Personal Development (at Orama Public School).",
    "He is passionate about living the good life in balance with Earth’s systems and is inspired by the nature-connection approach of CEL.",
    "Working with CEL has encouraged Benn to see the links between Permaculture and the self. He enjoys collaborating with CEL mentors to enrich his teaching by incorporating nature-connection, deep ecology and place-based practices.",
  ],
};
const Chris = {
  name: "Chris Pinkerton",
  pic: ChrisPic,
  titles: "Bookkeeper ",
  bio: [
    "Having grown up in the bush surrounding Bellingen, Chris has a keen interest in sustainability and all things nature. He was drawn to helping do CEL's books by the warm fuzzy feeling it gave him to help an organisation that is actually helping empower people with the knowledge and skills to help the world we live in.",
  ],
};
const Emily = {
  name: "Emily Markwell",
  pic: EmilyPic,
  titles: "Board Member and and Community Education Faciltator",
  bio: [
    "Emily has a passion for helping purpose led organisations and initiatives to thrive and grow. This is what she has spent the past three years doing with CEL's Nature Academy program, and continues to do with CEL - providing her skills and knowledge to support and promote all of the amazing programs CEL runs. Having recently started a degree in Education, Emily is excited to bring her existing experience to empower and inspire a new generation of nature lovers.",
  ],
};
const Erin = {
  name: "Erin Chapman",
  pic: ErinPic,
  titles: "Board Member and Nature Connection Mentor",
  bio: [
    "Erin is a mother of three, a teacher, an advocate for children and a passionate nature playworker! With seven years experience in the field of early childhood Erin brings her knowledge of child development, play, curriculum design and early nature connection activities to CEL. ",
    "Erin prides herself on her natural ability to relate to children, she is a passionate advocate for children’s rights to play in nature and an experienced outdoor educator. 2017 saw Erin embark on a new nature play path, she left the industry of child care to develop a new enterprise on the Coffs Coast – Nature Play Academy. ",
    "Nature Play Academy’s unique approach is focused on child directed play, our workshops provide children with a variety of opportunities to make discoveries, lead an experience in nature and build resilience. Our creative play programs empower confidence and connection in nature. Through the success of Nature Play Academy Erin has come to join CEL and implements the Beetle Buddies program, an early childhood nature play workshop operating during school terms.",
  ],
};
const Jane = {
  name: "Jane Grebert",
  pic: JanePic,
  titles: "Nature Connection Mentor, Youth Activities Facilitator",
  bio: [
    "Born and educated in South Australia, Jane came to Gumbaynggirr country- Bellingen in 2009. A qualified teacher, Jane has Bachelor of Education (Middle/ Secondary), and a Bachelor of Applied Science (Biodiversity, Environmental and Park Management). Jane’s work has ranged from working with youth in wild and wilderness locations to geography and science teaching, composting, waste education, water literacy in schools and as an Education Ranger with National Parks and Wildlife NSW",
    "Jane has a passion for connecting young people with/as nature and enjoys creatively collaborating with other like-minded mentors in this important work through the Centre for Ecological Learning.",
  ],
};
const Jason = {
  name: "Jason John",
  pic: JasonPic,
  titles: "Community Education facilitator",
  bio: [
    "Jason was one of the original founders of the Bellingen Shire Learning Alliance project as part of Transition Bellingen, and is currently the coordinator of the Bellingen Sustainability Centre. Jason is a Uniting Church minister, who for the last two decades has helped the church collaborate with others of good will to explore what it means to live as better members of the Earth family, encouraging those who need to, to return from their prodigal ways. Until 2020 he was a Uniting Earth Advocate (yes, that was a real job title),2022 took on the role as the Bellinger Landcare Coordinator and also looks after their Regenerative Agriculture Project. He has a degree in theology, and honours in zoology, postgrad diploma in environmental studies and couldn't resist a PhD in evolutionary biology and theology. He lives in Bellingen with his family and all their forest neighbours. He tries to maintain a biocentric view of the world, and to stop and smell the wattle, even though it gives him hay-fever. (Wattle-fever?)",
  ],
};
const Lisa = {
  name: "Lisa Siegel",
  pic: LisaPic,
  titles:
    "Board Member, Nature Connection Mentor, and Deep Ecology Facilitator",
  bio: [
    " Lisa was instrumental in helping a group of students establish Bellingen EYE in 2007, which led to the formation of CEL in 2011. Lisa has had multiple roles in CEL over the years, including board chairperson, treasurer, programming, facilitation, and administration.",
    " Lisa was named the 2022 National Environmental Educator of the Year by the Australian Association for Environmental Education (AAEE).",
    " Lisa has a PhD in Environmental Education and works at Southern Cross University in the Faculty of Education where she is a lecturer and a part of the Sustainability, Environmental, and Arts Education (SEAE) Research Cluster. Lisa has also trained in Deep Ecology/The Work That Reconnects with John Seed and Joanna Macy, and has been a student of Insight Buddhism and a member of Tallowwood Sangha for ten years.",
    " Lisa also loves being a mother and an auntie, a member of Bellingen Playback Theatre and Bellingen Write Club, and hanging out with her family, both human and more-than-human.",
  ],
};
const MarkG = {
  name: "Mark Graham",
  pic: MarkGPic,
  titles: "Community Education facilitator",
  bio: [
    "Mark is one of CEL's dedicated Community Educators, leading workshops and events on ecological connections. He is the Principal Ecologist with the Bellingen Nature Company, a custodian of a network of private conservation reserves from the head of the Bellinger Valley to the highest peaks of the Dorrigo Plateau and into the gorges of the Nymboida River, a tour guide, an educator and an advocate for a future filled with abundant life. Throughout his career he has worked on gaining a better understanding of our globally significant biodiversity as well as on the elements of water and fire. He sits on various boards and committees of companies and community groups and through all his work seeks to empower communities with knowledge of our life support systems and to motivate and inspire them to protect and expand them. He recognises that he lives, works and plays on unceded Gumbaynggirr country, pays the greatest respect to the Elders, the Ancestors and the Spirits of this land and seeks to work for the benefit and wellbeing of all Gumbaynggirr country and culture.",
  ],
};
const MarkT = {
  name: "Mark Taylor",
  pic: MarkTPic,
  titles: "Communications and Publicity",
  bio: [
    "Mark is a community arts producer and educator with a focus on collaborative documentary. His interest in ecological education and working with CEL emerged after starting a family and realising that we need to restore our connection with nature and teach our children the same. Skilled in screen media production, photography & graphic design, Mark graduated from the Australian Film and Television Radio School (AFTRS) as a documentary filmmaker and has made a number of broadcasted films and is currently studying Indigenous Knowledge at Southern Cross University. ",
    "Mark has worked extensively as a digital media educator, working within community settings and schools to empower young people through the power of storytelling. In 2005 Mark co-founded the Sydney Latin American Film Festival and the Pachamama Festival in 2013 as a means of sharing stories and inspiration from Latin America whilst raising funds for grassroots organisations.",
  ],
};
const Olivia = {
  name: "Olivia Bernardini",
  pic: OliviaPic,
  titles: "Deep Ecology Facilitator, Community Education Facilitator",
  bio: [
    "Born in Italy, Olivia became involved in social and environmental activism in the UK. This led her to the deeply transformative experience of living up a tree for over a year in an attempt to save an ancient oak forest from being destroyed by open cast coal mining. Olivia is a founding member of the Centre for Ecological Learning and has a diverse range of qualifications, skills and interests.",
    "Olivia holds a BA in Social Anthropology from the London School of Economics, a Master of Social Ecology at the University of Western Sydney and Counselling qualifications from the University of New England.",
    "Olivia has worked through CEL and other organisations designing and facilitating projects in environmental education, food resilience, community development and sustainability with people of all ages. She has worked in community engagement supporting resettlement of refugees; she has been teaching Yoga for over 20 years and has recently launched into private practice as a counsellor, specialising in Systemic and Family Constellations.",
    "Currently, she works with Centre for Ecological Learning facilitating Despair and Empowerment workshops and collaborating in whatever other interesting, creative and transformative project comes her way through this awesome organisation.",
    "In her free time, you may find Olivia immersed in a fresh or salty body of water at any time of the year and in any weather. Water is Life.",
  ],
};
const Tanya = {
  name: "Tanya Fox",
  pic: TanyaPic,
  titles: "Board Member and Deep Ecology Facilitator",
  bio: [
    "Tanya Fox is a group facilitator, counsellor, bodywork practitioner and nature lover. She has facilitated groups in a diverse range of disciplines, including deep ecology, creative expression, trauma work, grief and loss, language training and bodywork. Currently, she works with Centre for Ecological Learning facilitating Despair and Empowerment workshops, Feel The Magic charity organisation leading young people through grief support and education, and at Elemental Nature Connection Retreats facilitating interpersonal and nature connection activities.",
    "Drawing on qualifications in biological sciences, manual therapies and counselling, her work is aimed at developing and strengthening natural connections to our holistic selves, culture and wilderness, through deepening awareness, respect and play.",
  ],
};
const Zianna = {
  name: "Zianna Fuad",
  pic: ZiannaPic,
  titles: "Board Member, Deep Ecology facilitator, Community Education facilitator",
  bio: [
    "Since Zianna was 16 they have been dedicating their life to social and environmental justice causes; working with many diverse communities, from farmers to First Nations people who are on the frontline of extractive projects that threaten land, water, climate and culture. Zianna was part of a 6 year campaign that successfully achieved a permanent ban on fracking and unconventional gas across the state of Victoria.",
    "Zianna's sense of place and belonging has been shaped through living on Gumbaynggirr country and practicing Deep Ecology and The Work that Reconnects. They spent their teenage years with Bellingen EYE and went on to mentor younger people through nature awareness camps and bushwalks. They continue to run Deep Ecology workshops through CEL and in climate frontline spaces. ",
    "With a Bachelor in Environmental Geography and Politics Zianna is passionate about the way science, language and story can work together to inspire deep questioning, strengthen relationships, connect people to country and challenge the status quo.",
  ],
};

const TextBox = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
const PicsBox = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media only screen and (min-width: 750px) {
    flex-flow: row wrap;
  }
  @media only screen and (min-width: 1100px) {
  }
`;

const Team = () => {
  return (
    <PageContainer>
      <TextBox>
        <MainHeading>Our Team</MainHeading>
        <PicsBox>
          <TeamMember member={Lisa} />
          <TeamMember member={Benn} />
          <TeamMember member={Zianna} />          
          <TeamMember member={Erin} />
          <TeamMember member={Tanya} />
          <TeamMember member={Emily} />
          <TeamMember member={Jane} />
          <TeamMember member={Andrew} />
          <TeamMember member={Olivia} />          
          {/* <TeamMember member={Alison} /> */}
          {/* <TeamMember member={Jason} /> */}
          {/* <TeamMember member={MarkG} /> */}
          {/* <TeamMember member={MarkT} /> */}
          <TeamMember member={Chris} />
        </PicsBox>
      </TextBox>
      <Footer />
    </PageContainer>
  );
};

export default Team;
