import React from "react";
import styled from "styled-components";
import "../App.css";

import { PageContainer } from "../components/siteStyling/containerPage";
import { TextSection } from "../components/siteStyling/containerText";
import { TextDark } from "../components/siteStyling/textStyling";
import { Button } from "../components/siteStyling/button";

import background from "../images/mailing.jpg";

import Footer from "../components/footer";

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(251, 255, 251, 0.65);
  border-radius: 5px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.55);

  @media only screen and (min-width: 480px) {
    width: 60vw;
    margin: 15px;
  }
  @media only screen and (min-width: 768px) {
    width: 60vw;
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const Wrap = styled.div`
  width: 100%;
  min-height: 80vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  overflow: hidden;
`;

const Subscribe = () => {
  return (
    <PageContainer>
      <Wrap>
        <IframeContainer>
          <iframe 
          data-w-type="embedded"
          frameborder="0" 
          scrolling="no" marginheight="0" 
          marginwidth="0" 
          src="https://0nszq.mjt.lu/wgt/0nszq/x4mg/form?c=b552f82a" 
          width="100%"
          >
          </iframe>
          {/* <iframe
            title="Subscribe to Newsletter"
            data-w-token="2e187a5ec22d3961ae67"
            // data-w-type="pop-in"
            // frameborder="0" 
            // scrolling="yes" 
            // marginheight="0" 
            // marginwidth="0"
            src="https://0nszq.mjt.lu/wgt/0nszq/xm6k/form?c=367f6041"
            width="100%"
          // height="100%"
          // style="height: 0;"
          // allowtransparency="true"
          ></iframe>
          <iframe
            title="Subscribe to Newsletter"
            data-w-token="2e187a5ec22d3961ae67"
            data-w-type="trigger"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://0nszq.mjt.lu/wgt/0nszq/xm6k/trigger?c=9e2023c9"
            width="100%"
          // height="100%"
          // style="height: 0;"
          // allowtransparency="true"
          ></iframe> */}
        </IframeContainer>
      </Wrap>
      {/* <TextSection>
        <TextDark>View our newsletter archive at the link below:</TextDark>
        <Button>
          <a
            href="https://us3.campaign-archive.com/home/?u=c3958897eba57cefad26b838d&id=d18ff284f5"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            CEL Newsletter Archive
          </a>
        </Button>
      </TextSection> */}

      <Footer />
    </PageContainer>
  );
};

export default Subscribe;
