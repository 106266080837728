import React from "react";
import styled from "styled-components";

const ButtonStyle = styled.button`
  display: inline-block;
  padding: 15px 25px;
  margin: 2vh 0;
  font-size: 2em;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #7fa875;
  border: none;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

  &:hover {
    background-color: #3e8e41;
  }
`;
export const Button = ({ children }) => {
  return <ButtonStyle>{children}</ButtonStyle>;
};