import React from "react";
import styled from "styled-components";

const HeadingMainColor = styled.h2`
  text-transform: uppercase;
  font-size: 2em;
  text-align: center;
  color: #7fa875;
  margin: 30px 10px;
`;
export const MainHeading = ({ children }) => {
  return <HeadingMainColor>{children}</HeadingMainColor>;
};

const HeadingSubColor = styled.h2`
  text-transform: uppercase;
  font-size: 1.5em;
  text-align: center;
  color: #7fa875;
  margin-top: 30px;

`;
export const SubHeading = ({ children }) => {
  return <HeadingSubColor>{children}</HeadingSubColor>;
};

const HeadingSubDark = styled.h2`
  font-size: 2em;
  text-align: center;
  margin: 1vh 2vw;
  color: #666666;
  text-transform: uppercase;
`;
export const SubHeadingDark = ({ children }) => {
  return <HeadingSubDark>{children}</HeadingSubDark>;
};

const HeadingSubLight = styled.h2`
  font-size: 2em;
  text-align: center;
  margin: 1vh 2vw;
  color: white;
  text-transform: uppercase;
`;
export const SubHeadingLight = ({ children }) => {
  return <HeadingSubLight>{children}</HeadingSubLight>;
};

const TextLgeDark = styled.p`
  font-size: 1.6em;
  text-align: center;
  margin: 1vh 7vw;
  color: #666666;
`;
export const LargeDarkText = ({ children }) => {
  return <TextLgeDark>{children}</TextLgeDark>;
};

const TextLgeLight = styled.p`
  font-size: 1.7em;
  text-align: center;
  margin: 1vh 7vw;
  color: white;
`;
export const LargeLightText = ({ children }) => {
  return <TextLgeLight>{children}</TextLgeLight>;
};

const DarkText = styled.p`
  font-size: 1.1em;
  text-align: left;
  margin: 1vh 1vw;
  color: #666666;
`;
export const TextDark = ({ children }) => {
  return <DarkText>{children}</DarkText>;
};

const LightText = styled.p`
  font-size: 1.5em;
  text-align: center;
  color: white;
`;
export const TextLight = ({ children }) => {
  return <LightText>{children}</LightText>;
};

const LightTextSmall = styled.p`
  font-size: 1em;
  text-align: center;
  margin: 0 2vw;
  color: white;
`;
export const SmallTextLight = ({ children }) => {
  return <LightTextSmall>{children}</LightTextSmall>;
};

const LinkStyle = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
export const Link = ({children}) => {
    return <LinkStyle>{children}</LinkStyle>
}