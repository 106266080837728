export const menuTitles = [
  {
    title: "Home",
    path : "/",
    submenu: [],
  },
  {
    title: "Who We Are",
    path: "/tempPage",
    submenu: [
      {
        title: "About CEL",
        path: "/about",
        submenu: [],
      },
      {
        title: "Our Team",
        path: "/team",
        submenu: [],
      },
      {
        title: "Governance",
        path: "/governance",
        submenu: [],
      },      
    ],
  },
  {
    title: "What We Do",
    path: "/tempPage",
    submenu: [
      {
        title: "MAD Walks",
        path: "/MADWalks",
        submenu: [],
      },
      {
        title: "The Malaami Project",
        path: "/malaami",
        submenu: [],
      },
      {
        title: "Nature Connection Mentoring for Educators/Parents",
        path: "/natureAdults",
        submenu: [],
      },
      {
        title: "Bellingen Shire Learning Alliance",
        path: "/bsla",
        submenu: [],
      },
      {
        title: "Deep Ecology/The Work That Reconnects",
        path: "/deepEcology",
        submenu: [],
      },
      {
        title: "Nature Connection Activities for Young People",
        path: "/natureYouths",
        submenu: [],
      },
    ],
  },
  // {
  //   title: "Events",
  //   path: "/tempPage",
  //   submenu: [],
  // },
  // {
  //   title: "Stories",
  //   path: "/tempPage",
  //   submenu: [],
  // },
  {
    title: "How to Support Us",
    path: "/tempPage",
    submenu: [
      {
        title: "Sign-Up for our Newsletter",
        path: "/subscribe",
        submenu: [],
      },
      {
        title: "Join CEL",
        path: "/join",
        submenu: [],
      },
      
      {
        title: "Make a Tax-Deductible Donation",
        path: "/donate",
        submenu: [],
      },
    ],
  },
  // {
  //   title: "Contact",
  //   path: "/tempPage",
  //   submenu: [],
  // },
];
