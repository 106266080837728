import React from "react";
import styled from "styled-components";
import "../App.css";
import { Parallax } from "react-parallax";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import Peek from "../images/MADWalks/girl.jpg"
import Gather from "../images/MADWalks/tree_group.jpg"

import Footer from "../components/footer";

const MainHeading = styled.h2`
  text-transform: uppercase;
  font-size: 2em;
  text-align: center;
  color: #7fa875;
  margin: 30px 10px;
`;

const ImageSection = styled.div`
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const TopImage = styled(SectionContainerLge)`
  @media only screen and (min-width: 480px) {
    height: 50vh;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1800px) {
  }
`;
const BotImage = styled(SectionContainerLge)`
  @media only screen and (min-width: 480px) {
    height: 60vh;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const TextBox = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const TextGroupRow = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin: 0 10vw;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
    flex-flow: row nowrap;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 1200px;
  }
  @media only screen and (min-width: 1800px) {
  }
`;

const DarkText = styled.p`
  font-size: 1.1em;
  text-align: left;
  margin: 1vh 1vw;
  color: #666666;
`;

const TextArea = styled.div`
  background: rgba(0, 0, 0, 0.05);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
`;

const Bold = styled.p`
  font-size: 1.3em;
  text-align: center;
  margin: 1vh 2vw;
`;

const List = styled.ul`
  padding: 0 15px;
  font-size: 1em;
  text-align: centre;
  margin: 1vh 1vw;
  color: #666666;
  list-style-type: none;
`;

const ListItem = styled.li`
  padding-top: 5px;
`;

const ListHeading = styled.h4`
  font-size: 1.5em;
  margin: 5px 0;
  text-transform: uppercase;
`;

const LinkStyle = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const MADWalks = () => {
  return (
    <PageContainer>
      <TextBox>
        <MainHeading>MAD Wilderness Walks</MainHeading>
      </TextBox>
      <ImageSection>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={Peek}
          strength={100}
        >
          <TopImage />
        </Parallax>
      </ImageSection>
      <TextGroupRow>
        <DarkText>
          <br />
          <br />
          We are beyond excited to present the MAD (Make A Difference)  Wilderness Walks,  a new program for young people aged between 14 and 17, funded by Coffs Harbour City Council.
          <br />
          <br />
          The MAD walks will be grounded in Gumbaynggirr Country and knowledge. These walks will be a wonderful immersive experience and an opportunity to  learn a variety of nature awareness and leadership skills, including bush food, bird language, landscape mapping and lots more, while making new friends and having a great time.
          <br />
          <br />
          <TextArea>
            We will be offering two walks over the next months:
            <List>
              <ListItem>
                <ListHeading>DAY WALK</ListHeading>
                Tucker's Rock to Bundagen Headland
                <br />
                12th of July
                <br />
                Including: walking, Indigenous ways of walking the land, nature awareness and connection activities, bird language, landscape mapping.
              </ListItem>
              <br />
              <ListItem>
                <ListHeading>Weekend Walk & Overnight Campout</ListHeading>
                Yuraygirr National Park
                <br />
                17th & 18th of August
                <br />
                Including: walking, nature awareness and connection activities, leadership skills, bird language, landscape mapping, food prep for hiking and bush food, basic remote first aid.
              </ListItem>
            </List>
          </TextArea>
          <br />
          <br />
          We encourage young people to book into both longer walks, as the first will be a preparation for the second. Participants will be encouraged to take an active part in the planning and organising of the overnight walk, by attending planning meetings in preparation for it.
          <br />
          <br />
          <strong>For more information and to book your spot please follow <a href="https://forms.gle/oo1um2zYQaXntZoGA"
            target="_blank"
            rel="noreferrer noopener"
          >
            <LinkStyle>this link</LinkStyle>
          </a>
          </strong>
          <br />
          <br />
        </DarkText>
      </TextGroupRow>
      <ImageSection>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={Gather}
          strength={100}
        >
          <BotImage />
        </Parallax>
      </ImageSection>
      <Footer />
    </PageContainer>
  );
};

export default MADWalks;
