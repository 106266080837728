import React from "react";
import styled from "styled-components";
import { Parallax } from "react-parallax";
import "../App.css";

import { PageContainer } from "../components/siteStyling/containerPage";
import {
  SectionContainerLge,
  SectionContainerMd,
} from "../components/siteStyling/containerSection";

import {
  MainHeading,
  SubHeading,
  SubHeadingDark,
  LargeDarkText,
  TextDark,
  TextLight,
  Link,
} from "../components/siteStyling/textStyling";

import Slider from "../components/slider/slider";
import Footer from "../components/footer";

import fire from "../images/fire.jpg";
import wonder from "../images/wonder.jpg";

const LightTextSmall = styled.p`
  font-size: 1em;
  text-align: center;
  margin: 0 2vw;
  color: white;
`;

const TextGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin: 20px 2vw;

  @media only screen and (max-width: 750px) {
    flex-flow: column nowrap;
  }
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const TextColumn = styled.div`
  width: 99%;
  margin: 5px;
  padding: 5px;
  background-color: white;
  border-radius: 9px;
  max-width: 500px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Quote = styled.div`
  height: 100%;
  background-color: #3a8ba3;
  padding: 20px 20px;

  @media only screen and (max-width: 750px) {
  }
  @media only screen and (max-width: 550px) {
  }
`;

const Support = styled.div`
  margin: 5vh 10vw;

  @media only screen and (max-width: 550px) {
  }
`;

const Index = () => {
  return (
    <PageContainer>
      <Slider />
      <MainHeading>the centre for ecological learning</MainHeading>
      <LargeDarkText>
        provides immersive and collective educational experiences for all ages
        that help us understand ourselves as nature, deepen our relationship
        with the more-than-human world, and move towards more sustainable and
        balanced ways of living.
      </LargeDarkText>
      <TextGroup>
        <TextColumn>
          <SubHeading>IMMERSIVE EDUCATION</SubHeading>
          <TextDark>
            CEL focuses on experiential and interconnected environmental
            education experiences for all ages. We offer specially tailored
            camps and in-school programs for schools and youth groups. Our adult
            education experiences include Nature Connection Mentoring training,
            Deep Ecology workshops and retreats, and community workshops and
            events.
          </TextDark>
        </TextColumn>
        <TextColumn>
          <SubHeading>reconnection TO/As NATURE</SubHeading>
          <TextDark>
            As humans who are <i>a part of nature</i> (rather than{" "}
            <i>apart from it</i>), care for the more-than-human world becomes an
            imperative. Our Nature Connection and Deep Ecology programs help
            young people and adults alike connect to the natural world, as well
            as becoming skilled and knowledgeable in how to guide others to do
            the same.
          </TextDark>
        </TextColumn>
        <TextColumn>
          <SubHeading>SUSTAINABLE WAYS OF LIVING</SubHeading>
          <TextDark>
            Sustainability takes many forms – ecological sustainability,
            personal sustainability, and economic sustainability, to name a few.
            Our projects, especially the Bellingen Shire Learning Alliance,
            offer the chance to explore all types of sustainability, while
            working collectively to create more balanced ways of living on this
            planet.
          </TextDark>
        </TextColumn>
      </TextGroup>
      <Parallax blur={{ min: -15, max: 15 }} bgImage={fire} strength={-50}>
        <SectionContainerMd />
      </Parallax>
      <Quote>
        <TextLight>
          “To be alive in this beautiful, self-organizing universe -- to
          participate in the dance of life with senses to perceive it, lungs
          that breathe it, organs that draw nourishment from it -- is a wonder
          beyond words.”
        </TextLight>
        <LightTextSmall>– Joanna Macy</LightTextSmall>
      </Quote>
      <Parallax bgImage={wonder} bgImageSize="width: 95%;" strength={100}>
        <SectionContainerMd />
      </Parallax>
      <Support>
        <SubHeadingDark>LEARN ABOUT OUR CURRENT PROJECTS</SubHeadingDark>
        <TextDark>
          {" "}
          Including the Malaami Project, by visiting our{" "}
          <a
            href="https://www.facebook.com/CentreForEcologicalLearning"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Link>Facebook page</Link>
          </a>{" "}
          or signing up for {" "}
          <a
            href="https://www.cel.org.au/subscribe"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Link>our newsletter.</Link>
          </a>
        </TextDark>
        <SubHeadingDark>Support Us</SubHeadingDark>
        <TextDark>There are lots of ways to support the work we do.</TextDark>
        <TextDark>
          Come to an event,{" "}
          <a
            href="https://www.cel.org.au/join"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Link>become a member</Link>
          </a>
          ,{" "}
          <a
            href="https://www.givenow.com.au/helpcelsurviveandthrive"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Link>donate</Link>
          </a>
          ,{" "}
          <a
            href="https://www.cel.org.au/subscribe"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Link>or subscribe to our newsletter.</Link>
          </a>
        </TextDark>
      </Support>
      <Footer />
    </PageContainer>
  );
};

export default Index;
